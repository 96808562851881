import type { GridColDef } from "@mui/x-data-grid-pro";
import React from "react";

function isDemoMode(): boolean {
  const { search } = window.location;
  return new URLSearchParams(search).has("demo");
}

function isRedactMode(): boolean {
  const { search } = window.location;
  return new URLSearchParams(search).has("redact");
}

function formatFixedNumber(number: number, fractionDigits: number): number {
  return parseFloat(number.toFixed(fractionDigits));
}

function toPascalCase(input: string): string {
  return input
    .toLowerCase()
    .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
}

function snakeToTitle(snake: string): string {
  return snake
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function camelToLabel(camel: string): string {
  return (
    camel.charAt(0).toUpperCase() + camel.slice(1).replace(/([A-Z])/g, " $1")
  );
}

function replaceByWieldyId<T extends { wieldyId: string }>(
  envelopes: T[] | null,
  replacement: T,
): T[] | null {
  return (
    envelopes?.map((envelope) =>
      envelope.wieldyId === replacement.wieldyId ? replacement : envelope,
    ) ?? null
  );
}

function stringifyValues<
  T extends Record<
    string,
    string | number | boolean | null | Array<string> | DocumentList | undefined
  >,
>(obj: T): Record<string, string | null> {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value?.toString() ?? null,
    }),
    {} as Record<string, string | null>,
  );
}

function partialReplaceByWieldyId<
  T extends { wieldyId: string },
  R extends { wieldyId: string; practiceId: string },
>(envelopes: T[], partialEnvelope: R): T[] {
  return envelopes.map((envelope) =>
    envelope.wieldyId === partialEnvelope.wieldyId
      ? {
        ...envelope,
        ...partialEnvelope,
      }
      : envelope,
  );
}

function enumToStrings<T extends object>(enumType: T): Array<keyof T> {
  return (Object.keys(enumType) as Array<keyof T>).filter((v) =>
    Number.isNaN(Number(v)),
  );
}

/**
 * Convert an isodate to a Date type
 *
 * @param isoDate yyyy-mm-dd without timezone
 */
function isoToDate(isoDate: string): Date {
  return new Date(`${isoDate}T23:59:59`);
}

function isoNow(): string {
  return new Date().toISOString();
}

function getTodayISODate(): string {
  return new Date().toISOString().split("T")[0];
}

function sortAlphabetically(a: string, b: string): number {
  return a.localeCompare(b);
}

/**
 * Sorts grid columns based on a specified field order.
 *
 * First checks is if the provided gridColFieldOrder is a valid array and not empty.
 * If not valid, it returns the original gridColumns array without any changes.
 *
 * If valid, it creates an object mapping each field to its index in the gridColFieldOrder array.
 * Then, it sorts the gridColumns array based on the index.
 *
 * Fields not included in gridColFieldOrder but in gridColumns are moved to the end of the sorted array,
 * maintaining their original order. This ensures that all specified fields are prioritized in the sorting,
 * while unspecified fields are still included but placed at the end.
 */
function sortColumnsByFieldOrder({
  gridColumns,
  gridColFieldOrder,
}: {
  gridColumns: GridColDef<ClaimWithProcedureAndPatientMessage>[];
  gridColFieldOrder: string[];
}) {
  const invalidGridColFieldOrder =
    !Array.isArray(gridColFieldOrder) || !gridColFieldOrder.length;

  if (invalidGridColFieldOrder) {
    return gridColumns;
  }

  const colFieldOrderObj = gridColFieldOrder.reduce(
    (acc, field, index) => {
      acc[field] = index;
      return acc;
    },
    {} as Record<string, number>,
  );

  const sortedGridColumns = [...gridColumns].sort((a, b) => {
    // Fields not in gridColFieldOrder are assigned Infinity, moving the field to the
    // end
    const indexA = colFieldOrderObj[a.field] ?? Infinity;
    const indexB = colFieldOrderObj[b.field] ?? Infinity;
    return indexA - indexB;
  });

  return sortedGridColumns;
}

function toValueOptions<T>(
  array: T[],
  field: keyof T,
  sorter?: (a: T[keyof T], b: T[keyof T]) => number,
): T[keyof T][] {
  const values = new Set<T[keyof T]>();
  array.forEach((a) => {
    if (a[field]) {
      values.add(a[field]);
    }
  });
  const uniqueValues = Array.from(values);
  return sorter ? uniqueValues.sort(sorter) : uniqueValues;
}

function expectEnumValue<
  T extends { [key: string | number]: U },
  U extends string | number,
>(e: T, v: U): T[U] {
  const values = Object.values(e);
  if (!values.includes(v)) {
    throw new Error(
      `Unexpected enum value ${v}, expected one of ${enumToStrings(e)}`,
    );
  }
  return e[v];
}

function expectValue<T>(v: T | undefined | null): T {
  if (v === undefined || v === null) {
    throw new Error("Expected value to not be undefined or null");
  }
  return v;
}

function moneyMessageToNumber(money: MoneyMessage): number {
  return money.major + money.minor / 100; // TODO: Don't assume dollars.
}

function numberToMoneyMessage(money: number): MoneyMessage {
  const minorString = (money ?? "").toString().split(".")[1] ?? "0";
  return {
    major: Math.floor(money ?? 0),
    minor: Number(minorString.substring(0, 2)),
    code: "USD", // TODO: Don't assume dollars.
  };
}

interface HasAllProperties<T> {
  obj: Record<string, T>;
  properties: string[];
}

export interface DataTestProps extends React.HTMLAttributes<HTMLDivElement> {
  "data-testid"?: string;
}

/**
 * Returns truthy if obj has all specified properties, and each property's value is
 * either truthy or the number 0.
 */
function hasAllProperties<T>({ obj, properties }: HasAllProperties<T>) {
  return properties.every((key) => obj[key] || obj[key] === 0);
}

/**
 * Simulate a timed async call. Helpful for scenarios when
 * you want an artificial delay on the UI.
 * ex:  Load spinner flashing in & out rapidly
 */
const oneSecondInMS = 1000;
async function stall(stallTime = oneSecondInMS) {
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((resolve) => setTimeout(resolve, stallTime));
}

/**
 * Traverse a ReactNode (elements and text) to find the
 * deepest nested child that is a string.
 * */
function getNestedChildText(element: React.ReactNode): string | null {
  if (typeof element === "string") {
    return element;
  }
  if (React.isValidElement(element) && element.props.children) {
    return getNestedChildText(element.props.children);
  }
  return null;
}

function persistLocalStorage(key: string, state: object): void {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function retrieveLocalStorage<T>(key: string): T | null {
  try {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to parse local storage: ${error}`);
    return null;
  }
}

export {
  camelToLabel,
  enumToStrings,
  expectEnumValue,
  expectValue,
  formatFixedNumber,
  getNestedChildText,
  hasAllProperties,
  isDemoMode,
  isoNow,
  isoToDate,
  isRedactMode,
  sortAlphabetically,
  toPascalCase,
  toValueOptions,
  moneyMessageToNumber,
  numberToMoneyMessage,
  partialReplaceByWieldyId,
  persistLocalStorage,
  replaceByWieldyId,
  retrieveLocalStorage,
  snakeToTitle,
  sortColumnsByFieldOrder,
  stall,
  stringifyValues,
  getTodayISODate,
};
