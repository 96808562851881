import { useAuth0 } from "@auth0/auth0-react";

import { getWieldyOrgId, USE_AUTH } from "../api/apiConfig";

const useDevMode = (): boolean => {
  const { user } = useAuth0();
  const { search } = window.location;
  const userIsAuthorized = !USE_AUTH || user?.org_id === getWieldyOrgId();
  return new URLSearchParams(search).has("devmode") && userIsAuthorized;
};

export default useDevMode;
