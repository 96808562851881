import EditOutlined from "@mui/icons-material/EditOutlined";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { constructPatientMessage } from "../../../../api/query/messages";
import useDevMode from "../../../../utils/devmode";
import Modal from "../../../Modal";
import PatientPaymentMethods from "../../../PatientPayments/views/PatientPaymentMethods";
import InsuranceDetailsForm, {
  InsuranceDetailsFields,
  toInsuranceDetailsFields,
} from "../../forms/InsuranceDetailsForm";
import { PatientDetailsFields } from "../../forms/PatientDetailsForm";
import { PatientDetailsForm } from "../../forms/PatientDetailsForm/PatientDetailsForm";
import { FormFields } from "../../forms/PatientDetailsForm/PatientDetailsForm.zod";
import { ResponsibleDetailsFields } from "../../patientsDashboard.interfaces";
import DetailsCard from "../../ui/DetailsCard";
import {
  removeNonDigits,
  toPatientDetailsFields,
  toResponsibleDetailsFields,
} from "../../utils";

interface PatientInfoTabProps {
  patient?: PatientMessage;
  onSave: (patient: PatientMessage) => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  triggerPatientEditModal: boolean;
  closePatientEditModal: () => void;
  practices: PracticeMessage[];
}

export function PatientInfoTab({
  patient,
  onSave,
  updateSnackBar,
  triggerPatientEditModal,
  closePatientEditModal,
  practices,
}: PatientInfoTabProps) {
  const theme = useTheme();
  const devMode = useDevMode();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect");

  const [showEditPatientsModal, setShowEditPatientsModal] = useState(
    !!redirect && !!patient,
  );

  const [showEditInsuranceModal, setShowEditInsuranceModal] = useState(false);

  const [defaultResponsibleForm, setDefaultResponsibleForm] = useState(false);

  const [draftPatient, setDraftPatient] = useState<PatientMessage | undefined>(
    !!redirect && !!patient ? constructPatientMessage(patient) : undefined,
  );

  const patientDetailsFields = toPatientDetailsFields(
    constructPatientMessage(patient),
  );
  const draftPatientDetailsFields = toPatientDetailsFields(
    constructPatientMessage(draftPatient),
  );

  const responsibleDetailsFields = toResponsibleDetailsFields(
    constructPatientMessage(patient),
  );

  const draftResponsibleDetailsFields = toResponsibleDetailsFields(
    constructPatientMessage(draftPatient),
  );

  const openEditPatientModal = () => {
    setDraftPatient(constructPatientMessage(patient));
    setShowEditPatientsModal(true);
  };

  useEffect(() => {
    if (triggerPatientEditModal) {
      openEditPatientModal();
      setDefaultResponsibleForm(true);
    }
  }, [triggerPatientEditModal]);

  const closeEditPatientModal = () => {
    setShowEditPatientsModal(false);
    closePatientEditModal();

    if (redirect) {
      searchParams.delete("redirect");
      const to = { pathname: redirect, search: searchParams.toString() };
      navigate(to);
    }
  };

  const openEditInsuranceModal = () => {
    setDraftPatient(constructPatientMessage(patient));
    setShowEditInsuranceModal(true);
  };

  const handleFormUpdate = (
    update:
      | PatientDetailsFields
      | ResponsibleDetailsFields
      | InsuranceDetailsFields,
  ) => {
    const updatedPatient: PatientMessage = {
      ...constructPatientMessage(draftPatient),
      ...update,
    };
    setDraftPatient(updatedPatient);
  };

  const onSubmit = (data: FormFields) => {
    const { isAdditionalInfoRequired, ...formFields } = data;

    if (!draftPatient) {
      return;
    }

    const updatedPatient: PatientMessage = {
      ...constructPatientMessage(draftPatient),
      ...formFields,
      ssn: data.ssn ? removeNonDigits(data.ssn) : null,
      dob: data.dob ? data.dob : draftPatient.dob,
    };

    onSave(updatedPatient);
  };

  const handleSave = () => {
    if (!draftPatient) {
      return;
    }
    onSave(draftPatient);
  };

  return (
    <>
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <DetailsCard
          title="Patient Details"
          headerAdornment={
            <Button
              startIcon={<EditOutlined />}
              color="info"
              onClick={openEditPatientModal}
            >
              Edit
            </Button>
          }
        >
          <Box sx={{ marginBottom: theme.spacing(3) }}>
            <PatientDetailsForm
              readOnly
              onSubmit={onSubmit}
              patientDetailsFields={patientDetailsFields}
              responsibleDetailsFields={responsibleDetailsFields}
              defaultResponsibleForm={defaultResponsibleForm}
              practices={practices}
            />
          </Box>
        </DetailsCard>
      </Box>
      {devMode && (
        <Box sx={{ marginBottom: theme.spacing(3) }}>
          <DetailsCard title="General Notes">
            <TextField label="Type a note" fullWidth multiline />
          </DetailsCard>
        </Box>
      )}
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <DetailsCard
          title="Insurance Details"
          headerAdornment={
            <Button
              startIcon={<EditOutlined />}
              color="info"
              onClick={openEditInsuranceModal}
            >
              Edit
            </Button>
          }
        >
          <InsuranceDetailsForm
            values={toInsuranceDetailsFields(constructPatientMessage(patient))}
          />
        </DetailsCard>
      </Box>
      {devMode && (
        <Box sx={{ marginBottom: theme.spacing(2) }}>
          <DetailsCard title="Payment Details">
            <PatientPaymentMethods
              patient={patient}
              updateSnackBar={updateSnackBar}
            />
          </DetailsCard>
        </Box>
      )}
      <Modal
        title={patient?.name ?? ""}
        open={showEditPatientsModal}
        onClose={closeEditPatientModal}
      >
        <Grid
          container
          sx={{ height: "100%", margin: `${theme.spacing(7)} 0` }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={5}>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              Patient Details
            </Typography>
            <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
              <PatientDetailsForm
                onSubmit={onSubmit}
                patientDetailsFields={draftPatientDetailsFields}
                responsibleDetailsFields={draftResponsibleDetailsFields}
                defaultResponsibleForm={defaultResponsibleForm}
                practices={practices}
              />
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        title={patient?.name ?? ""}
        open={showEditInsuranceModal}
        onClose={() => setShowEditInsuranceModal(false)}
      >
        <Grid
          container
          sx={{ height: "100%", margin: `${theme.spacing(7)} 0` }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={5}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "600", marginBottom: theme.spacing(3) }}
            >
              Insurance Details
            </Typography>
            <Box sx={{ marginBottom: theme.spacing(3) }}>
              <InsuranceDetailsForm
                values={toInsuranceDetailsFields(
                  constructPatientMessage(draftPatient),
                )}
                onUpdate={handleFormUpdate}
                editable
              />
            </Box>
            <Button
              variant="contained"
              color="success"
              size="large"
              sx={{ fontWeight: 600 }}
              onClick={handleSave}
              fullWidth
            >
              Save Insurance Details
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

PatientInfoTab.defaultProps = {
  patient: undefined,
};
