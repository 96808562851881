export enum PostedState {
  UNPOSTED,
  FAILURE,
  AUTO_POSTED,
  MANUALLY_POSTED,
  POSTING,
  RETRY,
  PENDING,
}

export const POLL_INTERVAL = 1000;
export const MAX_POLL_TIME = 3600000;

const successStates = [
  PostedState[PostedState.AUTO_POSTED],
  PostedState[PostedState.MANUALLY_POSTED],
];

const inProgressStates = [
  PostedState[PostedState.PENDING],
  PostedState[PostedState.POSTING],
];

const failureStates = [
  PostedState[PostedState.FAILURE],
  PostedState[PostedState.RETRY],
];

export function hasPosted(claim: ClaimWithProcedureMessage): boolean {
  return successStates.includes(claim.postedState);
}

export function hasFailed(claim: ClaimWithProcedureMessage): boolean {
  return failureStates.includes(claim.postedState);
}

export function postingStatusSuccess(
  postingStatus: ClaimPostingStatus,
): boolean {
  return successStates.includes(postingStatus.postedState);
}

export function postingStatusInProgress(
  postingStatus: ClaimPostingStatus,
): boolean {
  return inProgressStates.includes(postingStatus.postedState);
}

export function needsPolling(
  claim: ClaimWithProcedureAndPatientMessage,
): boolean {
  return [
    PostedState[PostedState.POSTING],
    PostedState[PostedState.PENDING],
  ].includes(claim.postedState);
}
