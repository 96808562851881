import "./PatientProfileHeader.css";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

import useDevMode from "../../../../utils/devmode";
import { hasAllProperties } from "../../../../utils/utils";
import { redactText } from "../../../redact";
import { ResponsibleDetailsFields } from "../../forms/ResponsibleDetailsForm";
import { formatSSN } from "../../utils";

function formatDob(dob: string) {
  if (dob === "-") {
    return dob;
  }
  return dayjs(dob).format("MM/DD/YYYY");
}

interface PatientProfileHeaderProps {
  onClickBackButton: () => void;
  patient?: PatientMessage;
  openPatientEditModal: () => void;
  activePaymentPlans?: PaymentPlanMessage[];
}

type RequiredPaymentFields = keyof ResponsibleDetailsFields;

const requiredPaymentFields: RequiredPaymentFields[] = [
  "responsibleName",
  "responsibleMobilePhone",
  "responsibleEmail",
  "responsibleAddress",
  "responsibleCity",
  "responsibleState",
  "responsibleZipCode",
];

function BlockedPaymentButton({
  openPatientEditModal,
}: {
  openPatientEditModal: () => void;
}) {
  return (
    <Button
      color="warning"
      variant="contained"
      startIcon={<PriorityHighOutlinedIcon />}
      onClick={() => {
        openPatientEditModal();
      }}
      data-testid="complete-profile-button"
    >
      Complete Profile
    </Button>
  );
}

function PaymentDropdownButton({ patient }: { patient?: PatientMessage }) {
  const navigate = useNavigate();
  const devMode = useDevMode();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!devMode) {
    return null;
  }
  return (
    <>
      <Button
        startIcon={<PaymentsOutlinedIcon />}
        endIcon={<ArrowDropDownIcon />}
        variant="contained"
        color="success"
        id="payments-button"
        aria-controls={open ? "payments-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        data-testid="addPayment"
      >
        Add payment
      </Button>
      <Menu
        id="payments-menu"
        MenuListProps={{
          "aria-labelledby": "payments-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          list: "patientProfileHeaderMenuItem",
          root: "patientProfileHeaderMenuRoot",
        }}
      >
        <MenuItem
          data-testid="recurringPayment"
          onClick={() => {
            navigate(
              `/patients/${patient?.wieldyId}/payment-plans/new?devmode=true`,
            );
            handleClose();
          }}
          disableRipple
        >
          Recurring Payment
        </MenuItem>
        {/* Not implemented yet */}
        <MenuItem
          data-testid="accountingEntry"
          onClick={() => {
            navigate(`/patients/${patient?.wieldyId}/ledger/new?devmode=true`);
            handleClose();
          }}
          disableRipple
        >
          Accounting Entry
        </MenuItem>
      </Menu>
    </>
  );
}
PaymentDropdownButton.defaultProps = {
  patient: undefined,
};

export default function PatientProfileHeader({
  patient,
  onClickBackButton,
  openPatientEditModal,
  activePaymentPlans,
}: PatientProfileHeaderProps) {
  const theme = useTheme();

  const hasRequiredPaymentFields = patient
    ? hasAllProperties({ obj: patient, properties: requiredPaymentFields })
    : false;

  const hasNoActivePaymentPlan =
    Array.isArray(activePaymentPlans) && activePaymentPlans?.length === 0;

  const missingRequiredPaymentFields = patient && !hasRequiredPaymentFields;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      sx={{
        padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
        borderBottom: "1px solid rgba(37, 35, 63, 0.12)",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          sx={{
            margin: `0 ${theme.spacing(2)} 0 0`,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            <IconButton
              sx={{ color: theme.palette.primary.dark }}
              onClick={onClickBackButton}
            >
              <ArrowBack />
            </IconButton>
            <span data-sr-redact data-testid="name">
              {redactText(patient?.name ?? "-")}
            </span>
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box
          sx={{
            margin: `0 ${theme.spacing(2)}`,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            <span style={{ color: theme.palette.text.secondary }}>DoB: </span>
            <span data-sr-redact data-testid="dob">
              {redactText(formatDob(patient?.dob ?? "-"))}
            </span>
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box
          sx={{
            margin: `0 ${theme.spacing(2)}`,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            <span style={{ color: theme.palette.text.secondary }}>SSN: </span>
            <span data-sr-redact data-testid="ssn">
              {redactText(formatSSN(patient?.ssn ?? "-"))}
            </span>
          </Typography>
        </Box>
      </Box>
      <Box>
        {hasRequiredPaymentFields && hasNoActivePaymentPlan && (
          <PaymentDropdownButton patient={patient} />
        )}
        {missingRequiredPaymentFields && (
          <BlockedPaymentButton openPatientEditModal={openPatientEditModal} />
        )}
      </Box>
    </Box>
  );
}

PatientProfileHeader.defaultProps = {
  patient: undefined,
  activePaymentPlans: undefined,
};
