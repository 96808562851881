import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ClaimStatus } from "../../status";
import useDevMode from "../../utils/devmode";
import ClaimsTable from "../ClaimsTable";
import Loading from "../Loading";
import { PostedState } from "../posting";

enum PREDEFINED_FILTERS {
  REVIEW = "Review",
  DENIALS = "Denials",
  ALL_CLAIMS = "All Claims",
}

function filterClaims(
  claims: ClaimWithProcedureAndPatientMessage[],
  selectedPractices: PracticeMessage["name"][],
  selectedFilter: PREDEFINED_FILTERS,
) {
  const selectedPracticeSet = new Set(selectedPractices);

  return claims.filter((claim) => {
    const practiceMatch = selectedPracticeSet.has(claim.practiceDisplayName);

    let filterMatch = true;
    if (selectedFilter === PREDEFINED_FILTERS.REVIEW) {
      filterMatch = [
        PostedState[PostedState.UNPOSTED],
        PostedState[PostedState.RETRY],
        PostedState[PostedState.FAILURE],
      ].includes(claim.postedState);
    } else if (selectedFilter === PREDEFINED_FILTERS.DENIALS) {
      filterMatch = [
        ClaimStatus[ClaimStatus.DENIED],
        ClaimStatus[ClaimStatus.PARTIALLY_DENIED],
      ].includes(claim.claimStatus?.toUpperCase() ?? "");
    }
    return practiceMatch && filterMatch;
  });
}

interface ToDosListViewProps {
  claims: ClaimWithProcedureAndPatientMessage[];
  loading: boolean;
  updateClaim: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  onSaveClaim: (claim: ClaimMessage) => Promise<void>;
  onSaveProcedure: (procedure: ProcedureMessage) => Promise<void>;
  postToPMS: (claim: ClaimWithProcedureAndPatientMessage) => void;
  batchPostPMS: () => void;
  isBatchPosting: boolean;
  load: () => Promise<void>;
  reset: () => void;
  selectedPractices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
}

export default function ToDosListView({
  claims,
  loading,
  updateClaim,
  onSaveClaim,
  onSaveProcedure,
  postToPMS,
  batchPostPMS,
  isBatchPosting,
  load,
  reset,
  selectedPractices,
  supportedPayers,
}: ToDosListViewProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const statusParam = searchParams.get("status");

  const getInitialFilter = (status: string | null): PREDEFINED_FILTERS => {
    if (!status) {
      return PREDEFINED_FILTERS.ALL_CLAIMS;
    }
    const formattedStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    return Object.values(PREDEFINED_FILTERS).includes(
      formattedStatus as PREDEFINED_FILTERS,
    )
      ? (formattedStatus as PREDEFINED_FILTERS)
      : PREDEFINED_FILTERS.ALL_CLAIMS;
  };

  const [selectedFilter, setSelectedFilter] =
    React.useState<PREDEFINED_FILTERS>(getInitialFilter(statusParam));

  const devMode = useDevMode();

  useEffect(() => {
    load();
    return reset;
  }, []);

  if (loading) {
    return (
      <div style={{ display: "flex", height: "80vh" }}>
        <Loading />
      </div>
    );
  }

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newFilter: PREDEFINED_FILTERS,
  ) => {
    if (newFilter) {
      setSelectedFilter(newFilter);
      const params = new URLSearchParams(location.search);
      params.set("status", newFilter.toLowerCase());
      navigate({ search: params.toString() }, { replace: true });
    }
  };

  const selectedPracticeNames = selectedPractices.map(
    (practice) => practice.displayName,
  );

  return (
    <>
      <h1>Claims</h1>
      {devMode ? (
        <div>
          <Button
            variant="contained"
            onClick={batchPostPMS}
            disabled={isBatchPosting}
            sx={{ width: "auto", minWidth: "7.5rem", marginBottom: "1rem" }}
          >
            {!isBatchPosting ? (
              "Batch Post"
            ) : (
              <CircularProgress color="inherit" size={24} />
            )}
          </Button>
        </div>
      ) : null}
      <ToggleButtonGroup
        size="medium"
        color="primary"
        value={selectedFilter}
        exclusive
        onChange={handleChange}
        aria-label="Claim Predefine Filters"
      >
        {Object.values(PREDEFINED_FILTERS).map((filter) => (
          <ToggleButton
            key={filter}
            value={filter}
            aria-label={`${filter} filter`}
          >
            {filter}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "27px", padding: "0px 2px" }}
      >
        <Grid item xs={12}>
          <ClaimsTable
            payment={null}
            enablePagination
            enablePaymentNavigation
            claims={filterClaims(claims, selectedPracticeNames, selectedFilter)}
            isLoading={loading}
            onClaimUpdate={updateClaim}
            onPostToPMS={postToPMS}
            enableClaimDetails
            excludeByFieldNames={["paymentId", "notes"]}
            sortColumnOrder={[
              "urgency",
              "claimStatus",
              "availableSince",
              "practiceDisplayName",
              "payer",
              "wieldyPatientName",
              "claimId",
              "wieldyClaimDate",
              "claimPayerPays",
              "sources",
              "action",
              "postedState",
              "postedDateTime",
            ]}
            onSaveClaim={onSaveClaim}
            onSaveProcedure={onSaveProcedure}
            practices={selectedPractices}
            supportedPayers={supportedPayers}
            persistKey="claimsListView"
          />
        </Grid>
      </Grid>
    </>
  );
}
