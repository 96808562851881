import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";

export const getDefaultColumnVisibility: () => GridColumnVisibilityModel =
  () => ({
    action: true,
    availableSince: true,
    bankMatchState: false,
    claimGroupName: false,
    claimGroupNumber: false,
    claimId: true,
    claimPayerPays: true,
    claimPriorPaidAmount: false,
    claimStatus: true,
    claimType: false,
    documents: true,
    notes: true,
    patientDob: false,
    patientPayerNetwork: false,
    paymentId: true,
    payer: true,
    postedDateTime: true,
    postedState: true,
    practiceDisplayName: true,
    providerId: false,
    relationshipToSubscriber: false,
    reviewStatus: false,
    subscriberId: false,
    urgency: true,
    wieldyClaimDate: false,
    wieldyPatientName: true,
    wieldySubscriberName: false,
  });
