import "../../App.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Pagination } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  DataGridPro,
  gridClasses,
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  GridPagination,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridToolbar,
  GridValueFormatterParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import React from "react";

import { formatMoney } from "../../formatters";
import { ReviewStatus } from "../../status";
import useDataGridPersistence from "../../utils/datagrid";
import useDevMode from "../../utils/devmode";
import {
  enumToStrings,
  isoToDate,
  sortAlphabetically,
  toValueOptions,
} from "../../utils/utils";
import Loading from "../Loading";
import { renderCellRedacted } from "../redact";
import CustomNoRowsOverlay from "../TableNoDataOverlay";

const getColumnDefaultVisibility = () => ({
  date: true,
  eopPaymentAmount: true,
  isPosted: true,
  payee: false,
  payer: true,
  payerPaymentId: true,
  paymentStatus: true,
  paymentType: true,
  practice: true,
  productType: false,
});

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(_event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter(): React.ReactElement {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}

const renderPosted = (props: GridRenderCellParams) => (
  <Checkbox
    disabled
    color="primary"
    checked={props.value}
    inputProps={{
      "aria-labelledby": "isPosted",
    }}
    sx={{
      [`&.Mui-disabled`]: {
        color: (theme) => theme.palette.primary.main,
      },
    }}
  />
);

const getColumns = (
  payerValueOptions: string[],
  practiceValueOptions: string[],
  onSelectPayment: (payment: PaymentMessage) => void,
) => {
  const defaultColumns = [
    {
      flex: 1,
      field: "practiceDisplayName",
      headerName: "Practice",
      type: "singleSelect",
      valueOptions: practiceValueOptions.length
        ? practiceValueOptions
        : undefined,
    },
    {
      flex: 1,
      type: "date",
      field: "paymentDate",
      headerName: "Payment Date",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.paymentDate ? isoToDate(params.row.paymentDate) : "",
    },
    {
      flex: 1,
      field: "payer",
      headerName: "Payer",
      type: "singleSelect",
      valueGetter: (params: { value: string }) => {
        if (!params.value) {
          return params.value;
        }
        if (params.value.toLowerCase().includes("delta dental")) {
          return "Delta Dental";
        }
        return params.value;
      },
      renderCell: ({ row: { payer } }: GridRenderCellParams) => payer || "-",
      valueOptions: payerValueOptions.length ? payerValueOptions : undefined,
    },
    {
      flex: 1,
      field: "payee",
      headerName: "Payee",
    },
    {
      flex: 1,
      field: "payerPaymentId",
      headerName: "Payment ID",
      renderCell: renderCellRedacted,
    },
    {
      flex: 1,
      field: "paymentStatus",
      headerName: "Payment Status",
    },
    {
      flex: 1,
      field: "paymentType",
      headerName: "Payment Type",
    },
    {
      flex: 1,
      field: "productType",
      headerName: "Product Type",
    },
    {
      flex: 1,
      type: "number",
      field: "eopPaymentAmount",
      headerName: "Payment Amount",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
      renderCell: renderCellRedacted,
    },
    {
      flex: 1,
      field: "isPosted",
      type: "boolean",
      headerName: "All Posted",
      renderCell: renderPosted,
    },
    {
      flex: 1,
      field: "",
      headerName: "Details",
      sortable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: GridRenderCellParams) => (
        // TODO: Replace with a React Router Link.
        <Button
          data-testid="toPaymentDetails"
          type="button"
          variant="outlined"
          style={{
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => {
            onSelectPayment(params.row);
          }}
        >
          <NavigateNextIcon
            style={{ cursor: "pointer" }}
            aria-label="Select Row"
          />
        </Button>
      ),
    },
  ];

  const devModeOnlyColumns = [
    {
      flex: 1,
      field: "reviewStatus",
      type: "singleSelect",
      headerName: "Review Status",
      valueOptions: enumToStrings(ReviewStatus).map((reviewStatus) =>
        reviewStatus.toUpperCase(),
      ),
    },
  ];

  if (useDevMode()) {
    defaultColumns.push(...devModeOnlyColumns);
  }
  return defaultColumns;
};

interface PaymentsTableProps {
  isLoading: boolean;
  payments: PaymentMessage[];
  onSelectPayment: (payment: PaymentMessage) => void;
  persistKey?: string;
}

export default function PaymentsTable({
  isLoading,
  payments,
  onSelectPayment,
  persistKey,
}: PaymentsTableProps) {
  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  const columns = React.useMemo(
    () =>
      getColumns(
        toValueOptions(
          payments.map((payment) => ({
            ...payment,
            payer: payment.payer?.toLowerCase().includes("delta dental")
              ? "Delta Dental"
              : payment.payer,
          })),
          "payer",
          sortAlphabetically,
        ),
        toValueOptions(payments, "practiceDisplayName", sortAlphabetically),
        onSelectPayment,
      ),
    [payments, onSelectPayment],
  );

  const dataGridPersistence =
    persistKey !== undefined ? useDataGridPersistence(persistKey) : undefined;

  const devMode = useDevMode();

  return (
    <div style={payments && payments.length ? {} : { height: "25rem" }}>
      <DataGridPro
        loading={isLoading}
        apiRef={dataGridPersistence?.apiRef}
        rowHeight={72}
        getRowSpacing={getRowSpacing}
        rows={payments}
        columns={columns}
        getRowId={(row) => row.wieldyId}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: getColumnDefaultVisibility(),
            dimensions: {},
            orderedFields: [],
          },
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: {
            sortModel: [{ field: "paymentDate", sort: "desc" }],
          },
          ...(dataGridPersistence?.retrieveState(devMode) ?? {}),
        }}
        pageSizeOptions={[10, 20, 40]}
        pagination
        slots={{
          pagination: CustomFooter,
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: GridToolbar,
          loadingOverlay: Loading,
        }}
        sx={{
          [`& .${gridClasses.columnHeader}:first-child:focus, & .${gridClasses.cell}:first-child:focus`]:
            {
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
            },
          [`& .${gridClasses.columnHeader}:last-child:focus, & .${gridClasses.cell}:last-child:focus`]:
            {
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            },
        }}
      />
    </div>
  );
}

PaymentsTable.defaultProps = {
  persistKey: undefined,
};
