import { ErrorOutline } from "@mui/icons-material";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";

function SparklesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#22c55e"
      className="lucide lucide-sparkles"
    >
      <path d="M9.937 15.5A2 2 0 0 0 8.5 14.063l-6.135-1.582a.5.5 0 0 1 0-.962L8.5 9.936A2 2 0 0 0 9.937 8.5l1.582-6.135a.5.5 0 0 1 .963 0L14.063 8.5A2 2 0 0 0 15.5 9.937l6.135 1.581a.5.5 0 0 1 0 .964L15.5 14.063a2 2 0 0 0-1.437 1.437l-1.582 6.135a.5.5 0 0 1-.963 0z" />
      <path d="M20 3v4" />
      <path d="M22 5h-4" />
      <path d="M4 17v2" />
      <path d="M5 18H3" />
    </svg>
  );
}

function PracticeRow({
  practiceName,
  posted,
  needsReview,
}: {
  practiceName: string;
  posted: number;
  needsReview: number;
}) {
  const theme = useTheme();

  return (
    <TableRow sx={{ "&:last-child td": { borderBottom: 0 } }}>
      <TableCell sx={{ borderBottom: "1px solid #eee", paddingLeft: 3 }}>
        <Typography variant="subtitle1">{practiceName}</Typography>
      </TableCell>
      <TableCell align="right" sx={{ borderBottom: "1px solid #eee" }}>
        <Box sx={{ display: "flex", gap: 4, justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SparklesIcon />
            <Typography sx={{ color: theme.palette.success.main }}>
              {posted} Posted
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ErrorOutline sx={{ color: theme.palette.error.main, width: 20 }} />
            <Typography sx={{ color: theme.palette.error.main }}>
              {needsReview} Need Review
            </Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default function PracticeTable({ tasks }: { tasks: Task[] }) {
  return (
    <Table
      sx={{
        "& .MuiTableCell-root": {
          py: 3,
          px: 2,
        },
      }}
    >
      <TableBody>
        {tasks.map((task) => (
          <PracticeRow
            practiceName={task.practice}
            posted={task.metaData.successCount}
            needsReview={task.metaData.failureCount}
          />
        ))}
      </TableBody>
    </Table>
  );
}
