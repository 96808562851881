import React, { Dispatch } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import ReportsView from "./ReportsView";
import { ReportsStore, ReportsStoreAction } from "./store";

interface ReportsDashboardProps {
  store: ReportsStore;
  updateStore: Dispatch<ReportsStoreAction>;
  queryClient: QueryClient;
}

export default function ReportsDashboard({
  store,
  updateStore,
  queryClient,
}: ReportsDashboardProps) {
  const loadReports = async () => {
    const searchResponse = await queryClient.getPayments();
    const claims = await queryClient.getClaimsProcedures([]);
    updateStore({
      type: "SET_REPORTS",
      payments: searchResponse.data,
      claims,
    });
  };

  const resetReports = () => {
    updateStore({
      type: "RESET_REPORTS",
    });
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ReportsView
            payments={store.payments}
            claims={store.claims}
            load={loadReports}
            reset={resetReports}
          />
        }
      />
    </Routes>
  );
}
