import { Box } from "@mui/material";
import React from "react";

import Postie from "../TaskManager/Postie";

interface CopilotAssistProps {
  fullname: string;
  selectedPractices: PracticeMessage[]; // Added selectedPractices prop
}

function CopilotAssist({ fullname, selectedPractices }: CopilotAssistProps) {
  return (
    <Box
      sx={{
        padding: "2rem",
        margin: "0 auto",
        textAlign: "left",
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "100%",
        "@media (max-width: 600px)": {
          padding: "1rem",
        },
      }}
    >
      <Postie fullname={fullname} selectedPractices={selectedPractices} />
    </Box>
  );
}

export default CopilotAssist;
