import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  AlertTitle,
  Avatar,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useQueryClient from "../../api/query";
import PracticeTable from "../CoPilotAssist/PracticeTable";

function PostieIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 8V4H8" />
      <rect width="16" height="12" x="4" y="8" rx="2" />
      <path d="M2 14h2" />
      <path d="M20 14h2" />
      <path d="M15 13v2" />
      <path d="M9 13v2" />
    </svg>
  );
}

function pluralizeClaims(value: number): string {
  return value === 1 ? "claim" : "claims";
}

function summaryText(successes: number, reviews: number) {
  if (successes === 0 && reviews === 0) {
    return "There were no claims to take care of today.";
  }
  if (successes === 0 && reviews > 0) {
    return `I'd love your expertise on ${reviews} ${pluralizeClaims(reviews)}
    that need a quick review.`;
  }
  if (successes > 0 && reviews === 0) {
    return `I've taken care of all ${successes} ${pluralizeClaims(successes)}
    for you today!`;
  }
  return `I've taken care of ${successes} ${pluralizeClaims(successes)}
  for you today! I'd love your expertise
  on ${reviews} ${pluralizeClaims(reviews)} that need a quick review.`;
}

interface PostieProps {
  selectedPractices: PracticeMessage[];
  fullname: string;
}

export default function Postie({ selectedPractices, fullname }: PostieProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [tasks, setTasks] = useState<Task[]>([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const fetchedTasks = await queryClient.getTasks();
      setTasks(fetchedTasks);
    };

    fetchTasks();
  }, []);

  // Create a Set for fast lookup of selected practices
  const selectedPracticeNames = new Set(
    selectedPractices.map((practice) => practice.displayName),
  );

  const handleMarkCompleted = async (taskId: string) => {
    await queryClient.completeTask(taskId);
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };

  const handleTaskClick = async (tasksToHandle: Task[]) => {
    const completionPromises = tasksToHandle
      .filter((task) => task.status === "PENDING")
      .map((task) => handleMarkCompleted(task.id));
    await Promise.all(completionPromises);

    const redirectTask = tasksToHandle[0];
    if (redirectTask.link) {
      const [basePath, taskParams] = redirectTask.link.split("?");
      const taskSearchParams = new URLSearchParams(taskParams);
      const currentParams = new URLSearchParams(location.search);
      const mergedParams = new URLSearchParams(currentParams);
      taskSearchParams.forEach((value, key) => {
        mergedParams.set(key, value);
      });
      const newUrl =
        basePath +
        (mergedParams.toString() ? `?${mergedParams.toString()}` : "");
      navigate(newUrl);
    }
  };

  const filteredTasks = tasks.filter((task) =>
    selectedPracticeNames.has(task.practice),
  );

  const taskStats = filteredTasks.reduce(
    (acc, task) => {
      const metadata = task.metaData || {
        total: 0,
        failureCount: 0,
        successCount: 0,
      };
      return {
        total: acc.total + (metadata.total || 0),
        failureCount: acc.failureCount + (metadata.failureCount || 0),
        successCount: acc.successCount + (metadata.successCount || 0),
      };
    },
    { total: 0, failureCount: 0, successCount: 0 },
  );

  return (
    <Grid spacing={2} container>
      <Grid item md={1}>
        <Avatar
          sx={{
            bgcolor: "#EFF6FF",
            width: 70,
            height: 70,
            marginTop: "10px",
          }}
        >
          <PostieIcon />
        </Avatar>
      </Grid>
      <Grid item md={11}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            width: "100%",
            bgcolor: "#EFF6FF",
            marginBottom: "1rem",
            fontFamily: "inherit",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontFamily: "BN Bergen St",
                fontSize: "3.5rem",
                fontWeight: "700",
                lineHeight: "150%",
              }}
            >
              Hello{fullname ? ` ${fullname.split(" ")[0]}!` : "!"} I&#39;m
              Postie 👋
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: "1rem",
                fontWeight: 500,
                fontSize: "1rem",
                lineHeight: "150%",
              }}
            >
              {taskStats.total > 0
                ? summaryText(taskStats.successCount, taskStats.failureCount)
                : "Great work! You have finished reviewing all our claims for today 👏"}
            </Typography>
          </CardContent>
        </Card>
        {taskStats.successCount ? (
          <Alert
            icon={<CheckCircleIcon />}
            severity="success"
            sx={{
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              width: "100%",
              backgroundColor: "#E5ECDF",
              marginBottom: "1rem",
              color: "#000000",
              fontWeight: 500,
              fontSize: "1rem",
              lineHeight: "150%",
            }}
          >
            <AlertTitle
              sx={{ fontWeight: 600, color: "#000000", fontSize: "1.3rem" }}
            >
              Successfully Posted
            </AlertTitle>
            {`${taskStats.successCount} ${pluralizeClaims(taskStats.successCount)}
          processed and ready to go!`}
          </Alert>
        ) : null}
        {taskStats.failureCount > 0 ? (
          <Alert
            icon={<ErrorOutlineIcon />}
            severity="error"
            sx={{
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              width: "100%",
              backgroundColor: "#E9D7D9",
              color: "#000000",
              marginBottom: "1rem",
              fontWeight: 500,
              fontSize: "1rem",
              lineHeight: "150%",
            }}
          >
            <AlertTitle
              sx={{ fontWeight: 600, color: "#000000", fontSize: "1.3rem" }}
            >
              Need Your Review
            </AlertTitle>
            {`Could you help me with
          ${taskStats.failureCount}
          ${pluralizeClaims(taskStats.failureCount)}?`}
          </Alert>
        ) : null}
        {taskStats.total > 0 ? <PracticeTable tasks={filteredTasks} /> : null}

        {taskStats.failureCount > 0 && filteredTasks.length > 0 ? (
          <Button
            data-testid="reviewClaimsButton"
            sx={{
              borderRadius: "8px",
              width: "100%",
            }}
            color="primary"
            variant="contained"
            onClick={async () => {
              await handleTaskClick(filteredTasks);
            }}
          >
            {`Help Review ${pluralizeClaims(taskStats.failureCount)} (${taskStats.failureCount})`}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}
